import axios from "axios";
const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";

export const usuarioApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  //
  return {
    list: async ({ filters }) => {
      const { username = "", name = "", email = "", ordering = "" } = filters;
      let searchURL = `?search=${username}`;
      if (name) {
        searchURL = searchURL + `&name=${name}`;
      }
      if (email) {
        searchURL = searchURL + `&email=${email}`;
      }
      if (ordering) {
        searchURL = searchURL + `&ordering=${ordering}`;
      }
      //
      try {
        const usuarios = await axios.get(URL_BASE + `/usuario/${searchURL}`, {
          headers,
        });
        return usuarios.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("usuario list error", error);
        throw error;
      }
    },
    create: async (params) => {
      try {
        const response = await axios.post(
          URL_BASE + "/usuario/create",
          params,
          { headers }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    update: async (params) => {
      const { id } = params;
      try {
        const response = await axios.put(
          URL_BASE + `/usuario/${id}/update`,
          params,
          {
            headers,
          }
        );
        return response;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    detail: async (id) => {
      try {
        const response = await axios.get(URL_BASE + `/usuario/${id}/detail`, {
          headers,
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    delete: async (id) => {
      try {
        const data = await axios.delete(URL_BASE + `/usuario/${id}/delete`, {
          headers,
        });
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
  };
};
