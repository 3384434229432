import React, { useEffect } from "react";
import { TextIndentLeft, TextIndentRight } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import logo2 from "src/images/logo2.svg";
import { commonActions } from "src/modules/common/handler/redux";
import { empresaActions } from "src/modules/empresa/handlers/redux";
import { deleteCookie, deleteUserFromCookie } from "src/utils/helpers";

function Sidebar() {
  const { pathname } = useLocation();
  const links = [
    {
      name: "Empresa",
      to: "/empresas",
    },
    {
      name: "Usuarios",
      to: "/usuarios",
    },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sidebarCollapse = useSelector((state) => state.common.sidebarCollapse);
  const empresa = useSelector((state) => state.empresa.empresa);
  const toggleHandler = () => {
    dispatch(commonActions.sidebarCollapse(!sidebarCollapse));
  };
  const getActiveClass = (isActive, link) => {
    const activeClass = "text-sky-500 font-bold";
    const inactiveClass =
      "text-gray-500 no-underline hover:font-bold hover:text-sky-500";
    //
    const esLinkCompetencia = pathname.includes("/competencias/");
    if (esLinkCompetencia && link.competencia) {
      return activeClass;
    }
    return isActive ? activeClass : inactiveClass;
  };
  const getLogo = () => {
    if (empresa.logotipo)
      return <img className="sidebar-logo" src={empresa.logotipo} alt="logo" />;
    return (
      <div className="h-full w-full bg-sky-500 rounded-md flex items-center justify-center text-white text-3xl">
        {`${empresa?.nombre?.length > 0 ? empresa?.nombre[0] : ""}`}
      </div>
    );
  };
  useEffect(() => {
    if (Object.keys(empresa).length === 0) {
      // dispatch(empresaActions.empresa());
    }
  }, []);
  //
  return (
    <nav
      className={`flex flex-col pl-8 py-4 text-lg  shadow relative overflow-hidden transition-all ease-linear duration-300 ${
        !sidebarCollapse ? ` w-16` : `min-w-min`
      }`}
    >
      {/*  */}
      <div className="flex flex-row justify-between items-start relative mb-8">
        <div
          className={`flex items-center h-12 gap-2 transition ease-linear duration-50 ${
            !sidebarCollapse ? ` opacity-0 w-0 ` : `w-52 `
          }`}
        >
          <img src={logo2} alt="logo" />
          <span className="font-bold">Admin</span>
        </div>
        <button className="z-10 pr-4 pt-2" onClick={toggleHandler}>
          {!!sidebarCollapse && <TextIndentRight color={"#0ba3fb"} size={24} />}
          {!sidebarCollapse && <TextIndentLeft color={"#0ba3fb"} size={24} />}
        </button>
      </div>
      {/*  */}
      <div
        className={`flex flex-col gap-4 w-52 transition ease-linear duration-50 ${
          !sidebarCollapse ? ` opacity-0 ` : ``
        }`}
      >
        <div className={`flex h-16 mb-4`}>
          {!!sidebarCollapse && (
            <div className="flex items-center text-sm gap-2">
              <div className="h-16 w-16">{getLogo()}</div>
            </div>
          )}
        </div>
        {links.map((link, index) => (
          <NavLink
            {...link}
            key={`link-${index}`}
            onClick={() => {
              dispatch(commonActions.setTitle(link.name));
              dispatch(commonActions.showSidebar(true));
            }}
            className={({ isActive }) => getActiveClass(isActive, link)}
          >
            {link.name}
          </NavLink>
        ))}
        <div
          onClick={() => {
            deleteCookie("token", "/", ".wel.com.py");
            deleteCookie("token", "/"); // localhost
            deleteCookie("user", "/", ".wel.com.py");
            deleteCookie("user", "/"); // localhost
            deleteUserFromCookie(".wel.com.py");
            deleteUserFromCookie(); // localhost
            navigate("/auth/login/");
          }}
          className={
            "text-gray-500 no-underline hover:font-bold hover:text-sky-500"
          }
        >
          Cerrar sesión
        </div>
      </div>
    </nav>
  );
}

export default Sidebar;
