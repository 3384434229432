import React, { useRef } from "react";
import { ThreeDotsVertical } from "react-bootstrap-icons";

export const DotMenu = ({
  children = null,
  dir = "bottom",
  className = "",
  ...props
}) => {
  const containerRef = useRef(null);

  const { title = <ThreeDotsVertical /> } = props;
  let container = "bottom-0";
  let content = "top-0 pt-2";

  if (dir == "right") {
    container = "right-0 top-0";
    content = "left-0 pl-2";
  }
  if (dir == "left") {
    container = "left-0 top-0";
    content = "right-0 pr-2";
  }
  if (dir == "top") {
    container = "top-0";
    content = "bottom-0 pb-2";
  }

  const onFocus = ({ target }) => {
    let position = target.getBoundingClientRect();

    let fixed = containerRef.current;

    let top = position.top;
    let left = position.left;

    fixed.style.width = position.width + "px";
    fixed.style.height = position.height + "px";

    fixed.style.top = top + "px";
    fixed.style.left = left + "px";

    // @ts-ignore
    setTimeout(() => {
      target?.blur();
    }, 1);
  };

  const onWheel = ({ target }) => {
    !!process.env.REACT_APP_DEBUG && console.log(1);
    if (!containerRef?.current) {
      return;
    }

    containerRef.current.classList.remove("hover:block");
    setTimeout(() => {
      containerRef.current.classList.add("hover:block");
    }, 1);
  };

  return (
    <div className={`relative`} {...props}>
      <div
        onFocus={onFocus}
        className={`${className} px-2 peer outline-none`}
        // @ts-ignore
        tabIndex="0"
      >
        {title}
      </div>
      <div
        ref={containerRef}
        className="fixed hidden peer-focus:block hover:block z-10"
        onWheel={onWheel}
      >
        <div className={`absolute ${container}`}>
          <div className={`absolute ${content}`}>
            <div className="shadow-lg rounded-lg bg-white">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DotMenuItem = ({ children, className = "", ...props }) => {
  return (
    <div {...props} className={`px-3 py-2 ${className} hover:bg-blue-200`}>
      {children}
    </div>
  );
};
