import { useState } from "react";
import {
  ChevronDown,
  ChevronUp,
  Plus,
  Search,
  X,
} from "react-bootstrap-icons/dist";

const CustomTableHeader = ({ label, onOrder, onFilter }) => {
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [ordering, setOrdering] = useState("");
  const [inputValue, setInputValue] = useState("");

  const onShowSearchBar = () => {
    setShowSearchBar(true);
  };
  const onHideSearchBar = () => {
    setShowSearchBar(false);
    setInputValue("");
    onFilter("");
  };
  const onOrderingClick = (e) => {
    e.stopPropagation();
    switch (ordering) {
      case "asc":
        setOrdering("desc");
        onOrder("desc");
        break;
      case "desc":
        setOrdering("");
        onOrder("");
        break;
      default:
        setOrdering("asc");
        onOrder("asc");
        break;
    }
  };

  const onChangeText = (e) => {
    const inputText = e.target.value;
    onFilter(e.target.value);
    setInputValue(inputText);
  };
  return (
    <div className="flex items-center gap-4">
      {!showSearchBar && <span>{label}</span>}

      {!!showSearchBar && (
        <input
          value={inputValue}
          onChange={onChangeText}
          className="border rounded-md bg-slate-100 h-8 p-2"
          type="text"
        />
      )}

      {!!onOrder && (
        <span className="hover:cursor-pointer" onClick={onOrderingClick}>
          <ChevronUp
            color={ordering === "asc" || !ordering ? "black" : "gray"}
            size={12}
          ></ChevronUp>
          <ChevronDown
            color={ordering === "desc" || !ordering ? "black" : "gray"}
            size={12}
          ></ChevronDown>
        </span>
      )}
      {!!onFilter && (
        <span className="hover:cursor-pointer">
          {!showSearchBar && (
            <Search onClick={onShowSearchBar} size={18}></Search>
          )}
          {!!showSearchBar && <X onClick={onHideSearchBar} size={24}></X>}
        </span>
      )}
    </div>
  );
};

function CustomTable({
  data = [],
  headers = [],
  RowComponent,
  onActionButton,
  onSearch,
  searchPlaceholder = "Ingrese su búsqueda...",
  searchValue,
  loading = false,
}) {
  return (
    <>
      <div className="flex items-center justify-between mb-4">
        {/* SEARCH INPUT */}
        <div className="flex items-center bg-slate-100 rounded-md p-2">
          <input
            type="search"
            onChange={onSearch}
            placeholder={searchPlaceholder}
            className="bg-slate-100 outline-none"
            value={searchValue}
          />
          <Search />
        </div>
        {/* BOTON AÑADIR */}
        {!!onActionButton && (
          <div>
            <button
              onClick={onActionButton}
              className="bg-sky-500 text-white rounded-md py-2 px-4"
            >
              <div className="flex items-center justify-center">
                <Plus size={22} />
                <span>Añadir</span>
              </div>
            </button>
          </div>
        )}
      </div>
      {/* TABLA */}
      <div className="table w-full border rounded-md rounded-tr-mdx">
        <div className="table-row">
          {headers.length > 0 &&
            headers.map((header, idx) => (
              <div className={`table-cell border-b p-2 font-bold`}>
                <CustomTableHeader
                  label={header.label}
                  onOrder={header.onOrder}
                  onFilter={header.onFilter}
                />
              </div>
            ))}
        </div>
        {!loading &&
          data.length > 0 &&
          data.map((item, idx) => (
            <RowComponent key={`row-${idx}`} item={item}></RowComponent>
          ))}
        {!loading && data.length < 1 && (
          <div className="table-row">
            <div className="table-cell"></div>
            <div className="table-cell p-4">
              <span>No existen datos a mostrar</span>
            </div>
            <div className="table-cell"></div>
          </div>
        )}
        {!!loading && (
          <div className="table-row animate-pulse">
            <div className="table-cell"></div>
            <div className="table-cell"></div>
            <div className="table-cell p-4">
              <span className="text-slate-400">
                Cargando registros, por favor espere...
              </span>
            </div>
            <div className="table-cell"></div>
            <div className="table-cell"></div>
          </div>
        )}
      </div>
    </>
  );
}

export default CustomTable;
