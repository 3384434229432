import { call, put, select, takeEvery } from "redux-saga/effects";
import { getToken } from "src/utils/helpers";
import { rubroApi } from "./api";
import { rubroActions } from "./redux";

function* rubroUpdateSaga({ payload }) {
  const token = getToken();
  const rubro = yield select((state) => state.rubro.rubro);

  const params = { ...rubro };
  try {
    const data = yield call(rubroApi(token).update, params);
    //
    // yield put(rubroActions.rubroUpdateSuccess());
    yield put(rubroActions.rubro());
  } catch (error) {
    // yield put(rubroActions.rubroUpdateSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* getRubrosSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(rubroApi(token).list);
    console.log("getRubrosSaga data", data);
    yield put(rubroActions.setRubros(data.results)); //data.results
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

export function* rubroSagas() {
  yield takeEvery(rubroActions.getRubros.type, getRubrosSaga);
  // yield takeEvery(rubroActions.getRubro.type, getRubroSaga);
  // yield takeEvery(rubroActions.createRubro.type, createRubroSaga);
  // yield takeEvery(rubroActions.updateRubro.type, updateRubroSaga);
  // yield takeEvery(rubroActions.deleteRubro.type, deleteRubroSaga);
}
