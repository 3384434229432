import { createSlice } from "@reduxjs/toolkit";

const empresaSlice = createSlice({
  name: "Empresa",
  initialState: {
    empresas: [],
    empresas_count: 0,
    empresas_filter: {},
    empresas_loading: false,
    empresa: {},
    empresa_loading: false,
    usuarios: [],
    usuarios_count: 0,
    usuarios_filter: {},
    usuarios_loading: false,
    login_as_loading: false,
  },
  reducers: {
    getEmpresas: (state, { payload = {} }) => {
      state.empresas_loading = true;
    },
    setEmpresas: (state, { payload }) => {
      state.empresas = payload;
      state.empresas_loading = false;
    },
    setUsuariosCount: (state, { payload }) => {
      state.usuarios_count = payload;
    },
    setEmpresasCount: (state, { payload }) => {
      state.empresas_count = payload;
    },
    setEmpresasFilter: (state, { payload }) => {
      state.empresas_filter = payload;
      state.empresas_loading = true;
    },
    //
    getEmpresa: (state, { payload = {} }) => {
      state.empresa_loading = true;
    },
    setEmpresa: (state, { payload }) => {
      state.empresa = payload;
      state.empresa_loading = false;
    },
    //
    createEmpresa: (state, { payload }) => {},
    updateEmpresa: (state, { payload }) => {},
    deleteEmpresa: (state, { payload }) => {},
    //
    getUsuarios: (state, { payload = {} }) => {
      state.usuarios_loading = true;
    },
    setUsuarios: (state, { payload }) => {
      state.usuarios = payload;
      state.usuarios_loading = false;
    },
    setUsuariosFilter: (state, { payload }) => {
      state.usuarios_filter = payload.filters;
      state.usuarios_loading = true;
    },
    setLoginAsUserId: (state, { payload }) => {
      state.login_as_loading = true;
    },
    loginAsUserIdEnd: (state, { payload }) => {
      state.login_as_loading = false;
    },
  },
});

export const empresaActions = empresaSlice.actions;
export const empresaReducer = empresaSlice.reducer;
