import { createRouterReducer } from "@lagunovsky/redux-react-router";
import { authReducer } from "src/modules/auth/handlers/redux";
import { commonReducer } from "src/modules/common/handler/redux";
import { combineReducers } from "@reduxjs/toolkit";
import { empresaReducer } from "src/modules/empresa/handlers/redux";
import { rubroReducer } from "src/modules/rubro/handlers/redux";
import { usuarioReducer } from "src/modules/usuarios/handlers/redux";

export const rootReducer = (history) => {
  return combineReducers({
    router: createRouterReducer(history),
    auth: authReducer,
    common: commonReducer,
    empresa: empresaReducer,
    usuario: usuarioReducer,
    rubro: rubroReducer,
  });
};
