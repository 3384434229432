import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { rubroActions } from "src/modules/rubro/handlers/redux";
import { TIPO_PLANES } from "src/modules/common/helpers/constants";
import CustomDatePicker from "src/modules/common/components/CustomDatePicker";
import { usuarioActions } from "../handlers/redux";
import { useParams } from "react-router-dom";
import { formatDate } from "src/modules/common/helpers/helpers";
import { format } from "date-fns";

const UsuarioField = ({ field, formik }) => {
  const { label, name, type = "text", required = false } = field;
  // console.log("ufield", field);
  return (
    <div className="flex flex-col gap-2">
      <label className="font-semibold" htmlFor="name">
        {label}
        {!!required && <span className="text-red-500">*</span>}
      </label>
      <input
        id={name}
        name={name}
        type={type}
        className="border rounded-md p-1"
        onChange={formik.handleChange}
        value={formik.values[name]}
      />
      {formik.touched[name] && formik.errors[name] && (
        <div className="text-red-500">{formik.errors[name]}</div>
      )}
    </div>
  );
};

const UsuarioForm = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { idUsuario } = params;
  const usuario = useSelector((state) => state.usuario.usuario);
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirmPassword: "",
  };
  const validationSchema = Yup.object({
    first_name: Yup.string().required("El nombre es obligatorio"),
    last_name: Yup.string().required("El apellido es obligatorio"),
    email: Yup.string()
      .email("Ingresa un correo electrónico válido")
      .required("El correo electrónico es obligatorio"),
    password: Yup.string()
      // .min(8, "La contraseña debe tener al menos 8 caracteres")
      .required("La contraseña es obligatoria"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Las contraseñas no coinciden")
      .required("Confirma tu contraseña"),
  });
  const onSubmit = (values) => {
    console.log("onSubmit", values);
    let data = { ...values };
    data.username = values.email;
    //
    dispatch(usuarioActions.setUsuario(data));
    if (idUsuario) {
      dispatch(usuarioActions.updateUsuario());
    } else {
      dispatch(usuarioActions.createUsuario());
    }
    //
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log("useFormik", values);
      onSubmit(values);
    },
  });
  //
  useEffect(() => {
    dispatch(rubroActions.getRubros());
    console.log("idUsuario", idUsuario);
    dispatch(usuarioActions.setUsuario({}));
    if (!!idUsuario) {
      dispatch(usuarioActions.getUsuario(idUsuario));
    }
  }, []);

  useEffect(() => {
    formik.setValues({
      ...usuario,
    });
  }, [usuario]);

  const fields = [
    { label: "Nombre", name: "first_name", type: "text", required: true },
    { label: "Apellido", name: "last_name", type: "text", required: true },
    { label: "Email", name: "email", type: "email", required: true },
    { label: "Contraseña", name: "password", type: "password", required: true },
    {
      label: "Confirmar contraseña",
      name: "confirmPassword",
      type: "password",
      required: true,
    },
  ];

  return (
    <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2 mt-4">
      {fields.map((field, fidx) => (
        <UsuarioField key={`uf-${fidx}`} field={field} formik={formik} />
      ))}

      <button
        type="submit"
        className="rounded-md bg-sky-500 px-1 py-2 text-white mt-2"
      >
        Guardar
      </button>
    </form>
  );
};

export default UsuarioForm;
