import { call, put, select, takeEvery } from "redux-saga/effects";
import { getToken } from "src/utils/helpers";
import { empresaApi } from "./api";
import { empresaActions } from "./redux";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { push } from "@lagunovsky/redux-react-router";

function* createEmpresaSaga({ payload }) {
  const token = getToken();
  const empresa = yield select((state) => state.empresa.empresa);

  const params = { ...empresa };
  try {
    const data = yield call(empresaApi(token).create, params);
    //
    SuccessToast({ message: "Empresa creada con éxito!" });
    yield put(push("/empresas"));
    yield put(empresaActions.getEmpresas());
  } catch (error) {
    // yield put(empresaActions.empresaUpdateSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ha ocurrido un error" });
  }
}

function* updateEmpresaSaga({ payload }) {
  const token = getToken();
  const empresa = yield select((state) => state.empresa.empresa);
  const params = { ...empresa };
  //
  try {
    const data = yield call(empresaApi(token).update, params);
    //
    SuccessToast({ message: "Empresa editada con éxito!" });
    yield put(push("/empresas"));
    yield put(empresaActions.getEmpresas());
  } catch (error) {
    // yield put(empresaActions.empresaUpdateSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ha ocurrido un error" });
  }
}

function* getEmpresaSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(empresaApi(token).detail, payload);
    console.log("getEmpresaSaga data", data);
    yield put(empresaActions.setEmpresa(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* getEmpresasSaga({ payload }) {
  const token = getToken();
  const empresas_filter = yield select(
    (state) => state.empresa.empresas_filter
  );
  //
  try {
    const data = yield call(empresaApi(token).list, {
      filters: empresas_filter,
    });
    console.log("getEmpresasSaga data", data);
    yield put(empresaActions.setEmpresas(data.results));
    yield put(empresaActions.setEmpresasCount(data.count));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* deleteEmpresaSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(empresaApi(token).delete, payload);
    console.log("deleteEmpresaSaga data", data);
    yield put(empresaActions.getEmpresas());
    SuccessToast({ message: "Empresa eliminada con éxito!" });
  } catch (error) {
    ErrorToast({ message: "Ocurrió un error." });
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* getUsuariosSaga({ payload }) {
  const token = getToken();
  const empresa = yield select((state) => state.empresa.empresa);
  const usuarios_filter = yield select(
    (state) => state.empresa.usuarios_filter
  );
  try {
    const data = yield call(empresaApi(token).usuarios, {
      id: payload.id,
      filters: usuarios_filter,
    });
    // console.log("getEmpresaSaga data", data);
    yield put(empresaActions.setUsuarios(data.results));
    yield put(empresaActions.setUsuariosCount(data.count));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* loginAsSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(empresaApi(token).loginAs, payload);
    console.log("loginAsSaga data", data);
    yield put(empresaActions.loginAsUserIdEnd());
    const url = "https://app.wel.com.py/";
    // const url = "http://localhost:3001/";
    setTimeout(() => {
      window.open(url, "_blank", "noreferrer");
    }, 1500);
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

export function* empresaSagas() {
  yield takeEvery(empresaActions.getEmpresas.type, getEmpresasSaga);
  yield takeEvery(empresaActions.setEmpresasFilter.type, getEmpresasSaga);
  yield takeEvery(empresaActions.getEmpresa.type, getEmpresaSaga);
  yield takeEvery(empresaActions.createEmpresa.type, createEmpresaSaga);
  yield takeEvery(empresaActions.updateEmpresa.type, updateEmpresaSaga);
  yield takeEvery(empresaActions.deleteEmpresa.type, deleteEmpresaSaga);
  yield takeEvery(empresaActions.getUsuarios.type, getUsuariosSaga);
  yield takeEvery(empresaActions.setUsuariosFilter.type, getUsuariosSaga);
  yield takeEvery(empresaActions.setLoginAsUserId.type, loginAsSaga);
}
