import dayjs from "dayjs";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const capitalizeFirstLetter = function (string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const ObjectToString = (obj) => {
  let string = "";
  !!process.env.REACT_APP_DEBUG &&
    console.log("ObjectToString 0", !Array.isArray(obj));
  // if (!Array.isArray(obj)) return obj;
  // !!process.env.REACT_APP_DEBUG && console.log("ObjectToString 1");
  //
  Object.keys(obj).forEach((k) => (string += `${k}: ${obj[k]}`));
  !!process.env.REACT_APP_DEBUG && console.log("ObjectToString 2", string);
  return string;
};

export const ObjectToFormData = (item) => {
  let formData = new FormData();

  for (let key in item) {
    let value = item[key];
    if (value) formData.append(key, value);
  }

  return formData;
};

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length).replaceAll("\\054", ",");
    }
  }
  return "";
};

export const getUserFromCookie = () => {
  let user = {};
  user.nombre = getCookie("usuario_nombre").replaceAll('"', "");
  user.foto_empleado = getCookie("foto_empleado").replaceAll('"', "");
  user.es_super_admin =
    parseInt(getCookie("es_super_admin")) === 1 ? true : false;
  return user;
};

export const getToken = () => {
  let token = getCookie("token");
  if (!token) {
    token = getCookie("kento");
  }
  return token;
};

export const deleteCookie = (name, path, domain = "localhost") => {
  if (getCookie(name)) {
    document.cookie =
      name +
      "=" +
      (path ? ";path=" + path : "") +
      (domain ? ";domain=" + domain : "") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
};

export const deleteUserFromCookie = (domain = "localhost") => {
  deleteCookie("usuario_nombre", "/", domain);
  deleteCookie("foto_empleado", "/", domain);
  deleteCookie("es_super_admin", "/", domain);
};
