import React from "react";
import { Route, Routes } from "react-router-dom";
import { empresaRoutes } from "src/modules/empresa/handlers/routes";
import { usuarioRoutes } from "src/modules/usuarios/handlers/routes";

const routes = [...empresaRoutes, ...usuarioRoutes];

const RootRoutes = () => {
  return (
    <Routes>
      {routes.map((route, idx) => (
        <Route key={idx} {...route} />
      ))}
    </Routes>
  );
};

export default RootRoutes;
