import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: {},
    token: "",
    login_loading: false,
    error_message: "",
  },
  reducers: {
    login: (state, { payload }) => {
      state.login_loading = true;
    },
    refresh: (state, { payload }) => {
      state.login_loading = true;
    },
    loginError: (state, { payload }) => {
      state.error_message = payload;
      state.login_loading = false;
    },
    loginComplete: (state) => {
      state.login_loading = false;
    },
    setUser: (state, { payload }) => {
      state.user = payload.user;
      !!process.env.REACT_APP_DEBUG && console.log(payload.user);
    },
    setToken: (state, { payload }) => {
      state.token = payload.token;
      state.login_loading = false;
      !!process.env.REACT_APP_DEBUG && console.log(payload.token);
    },
  },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
