import { createSlice } from "@reduxjs/toolkit";

const rubroSlice = createSlice({
  name: "Rubro",
  initialState: {
    rubros: [],
    rubros_loading: false,
    rubro: {},
    rubro_loading: false,
  },
  reducers: {
    getRubros: (state, { payload = {} }) => {
      state.rubros_loading = true;
    },
    setRubros: (state, { payload }) => {
      state.rubros = payload;
      state.rubros_loading = false;
    },
    //
    getRubro: (state, { payload = {} }) => {
      state.rubro_loading = true;
    },
    setRubro: (state, { payload }) => {
      state.rubro = payload;
      state.rubro_loading = false;
    },
    //
    createRubro: (state, { payload }) => {},
    updateRubro: (state, { payload }) => {},
    deleteRubro: (state, { payload }) => {},
  },
});

export const rubroActions = rubroSlice.actions;
export const rubroReducer = rubroSlice.reducer;
