import axios from "axios";
const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";

export const rubroApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  //
  return {
    list: async () => {
      try {
        const rubros = await axios.get(URL_BASE + "/rubro/?page=1&page_size=500", { headers });
        return rubros.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("rubro list error", error);
        throw error;
      }
    },
    create: async (params) => {
      try {
        const response = await axios.post(URL_BASE + "/rubro/create", params, {
          headers,
        });
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    update: async (params) => {
      const { id } = params;
      try {
        const response = await axios.put(
          URL_BASE + `/rubro/${id}/update`,
          params,
          {
            headers,
          }
        );
        console.log("url rubro update", response)
        return response;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    detail: async (id) => {
      try {
        const response = await axios.get(URL_BASE + `/rubro/${id}/detail`, {
          headers,
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    delete: async (id) => {
      try {
        const data = await axios.delete(URL_BASE + `/rubro/${id}/delete`, {
          headers,
        });
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
  };
};
