import { Search } from "react-bootstrap-icons";

const SearchInput = ({ containerClass, value, onChange, ...props }) => {
  return (
    <div
      className={`flex items-center justify-between bg-slate-100 rounded-md p-2 flex-1x ${containerClass}`}
    >
      <input
        className="w-full outline-none bg-slate-100 px-1"
        type="search"
        value={value}
        onChange={onChange}
        {...props}
      />
      <Search />
    </div>
  );
};

export default SearchInput;
