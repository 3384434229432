import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { commonActions } from "src/modules/common/handler/redux";
import { TIPO_PLANES } from "src/modules/common/helpers/constants";
import { formatDate } from "src/modules/common/helpers/helpers";
import DataTable from "src/modules/common/components/DataTable";
import SearchInput from "src/modules/common/components/SearchInput";
import { empresaActions } from "../handlers/redux";
import { Plus } from "react-bootstrap-icons";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";

function Empresas() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const empresas = useSelector((state) => state.empresa.empresas);
  const empresas_count = useSelector((state) => state.empresa.empresas_count);
  const empresas_loading = useSelector(
    (state) => state.empresa.empresas_loading
  );
  const empresas_filter = useSelector((state) => state.empresa.empresas_filter);
  const [PageSize, setPageSize] = React.useState(10);
  //
  const onEditar = (item) => {
    navigate(`${item.id}/editar`);
  };
  const irUsuarios = (item) => {
    dispatch(empresaActions.setEmpresa(item));
    navigate(`${item.id}/usuarios`);
  };
  const getPlanDisplay = (planId) => {
    return TIPO_PLANES.find((tp, tpidx) => tp.value === planId).label;
  };
  const eliminarEmpresaHandler = (empresaId) => {
    let deseaEliminar = window.confirm(
      "Está seguro que desea eliminar la empresa seleccionada?"
    );
    if (deseaEliminar) {
      dispatch(empresaActions.deleteEmpresa(empresaId));
    }
  };
  //
  const setOrdering =
    (field) =>
    (order = "") => {
      let { ordering = "" } = empresas_filter;
      let orderingArray = ordering.split(",") || [];
      let emptyIndex = orderingArray.indexOf("");
      if (emptyIndex) {
        orderingArray.splice(emptyIndex, 1);
      }
      let fieldIndex = orderingArray.indexOf(field);
      fieldIndex =
        fieldIndex === -1 ? orderingArray.indexOf(`-${field}`) : fieldIndex;
      // si ya esta el campo, vemos si se modifica o elimina
      !!process.env.REACT_APP_DEBUG &&
        console.info("S==== setOrdering", ordering, orderingArray, fieldIndex);
      if (fieldIndex > 0) {
        orderingArray.splice(fieldIndex, 1);
        if (order) {
          field = order === "desc" ? `-${field}` : field;
          orderingArray[fieldIndex] = field;
        }
      } else {
        // si no está, lo agregamos
        field = order === "desc" ? `-${field}` : field;
        orderingArray.push(field);
        !!process.env.REACT_APP_DEBUG &&
          console.info("orderingArray 2", orderingArray);
      }
      ordering = orderingArray.join(",");
      //
      dispatch(
        empresaActions.setEmpresasFilter({
          ...empresas_filter,
          ordering: ordering,
        })
      );
    };
  const onPaginate = (page, pageSize) => {
    //
    dispatch(
      empresaActions.setEmpresasFilter({
        ...empresas_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };
  const onChangePageSize = (e) => {
    let value = e.target.value;
    dispatch(
      empresaActions.setEmpresasFilter({
        ...empresas_filter,
        pagination: {
          pageSize: parseInt(value),
          page: 1,
        },
      })
    );
    setPageSize(parseInt(value));
  };
  const datatableConfig = {
    headers: [
      {
        title: "Nombre",
        contentClass: "px-2 py-4",
        onOrder: setOrdering("nombre"),
        accessor: "nombre",
      },
      {
        title: "Colaboradores",
        contentClass: "px-2 py-4",
        onOrder: setOrdering("cantidad_colaboradores"),
        accessor: "cantidad_colaboradores",
      },
      {
        title: "Rubro",
        contentClass: "px-2 py-4",
        onOrder: setOrdering("rubro__nombre"),
        accessor: "rubro.nombre",
      },
      {
        title: "Finaliza",
        contentClass: "px-2 py-4",
        onOrder: setOrdering("fecha_vencimiento_plan"),
        // accessor: "fecha_vencimiento_plan",
        contentRenderer: (item) => (
          <>{formatDate(item.fecha_vencimiento_plan)}</>
        ),
      },
      {
        title: "Plan",
        contentClass: "px-2 py-4",
        onOrder: setOrdering("tipo_plan"),
        // accessor: "tipo_plan",
        contentRenderer: (item) => <>{getPlanDisplay(item.tipo_plan)}</>,
      },
    ],
    rowActions: [
      {
        label: "Editar",
        action: (item) => {
          onEditar(item);
        },
      },
      {
        label: "Usuarios",
        action: (item) => {
          irUsuarios(item);
        },
      },
      {
        label: "Eliminar",
        action: (item) => {
          eliminarEmpresaHandler(item.id);
        },
      },
    ],
  };
  React.useEffect(() => {
    dispatch(commonActions.setTitle("Empresas"));
    dispatch(commonActions.showSidebar(true));
    dispatch(empresaActions.getEmpresas());
    !!process.env.REACT_APP_DEBUG && console.info("empresas", empresas);
  }, []);
  //
  function addFilterFilter(field) {
    console.log("addFilterFilter", field);
    // return () => {};
    return (value) => {
      dispatch(
        empresaActions.setEmpresasFilter({
          ...empresas_filter,
          [`${field}`]: value,
        })
      );
    };
  }
  // const headers = [
  //   {
  //     label: "Nombre",
  //     onOrder: setOrdering("nombre"),
  //     onFilter: false,
  //   },
  //   {
  //     label: "Colaboradores",
  //     onOrder: false,
  //     onFilter: false,
  //   },
  //   {
  //     label: "Rubro",
  //     onOrder: setOrdering("rubro__nombre"),
  //     onFilter: addFilterFilter("rubroNombre"),
  //   },
  //   {
  //     label: "Finaliza",
  //     onOrder: setOrdering("fecha_vencimiento_plan"),
  //     onFilter: false,
  //   },
  //   {
  //     label: "Plan",
  //     onOrder: setOrdering("tipo_plan"),
  //     onFilter: false,
  //   },
  //   { label: "", onOrder: false, onFilter: false },
  // ];

  const searchEmpresaNombre = (e) => {
    let value = e.target.value;
    dispatch(
      empresaActions.setEmpresasFilter({
        ...empresas_filter,
        nombre: value,
      })
    );
  };
  //
  return (
    <>
      <div className="flex mb-8">
        <div className="w-full">
          <div className="container mt-4 flex flex-col mb-8">
            <div className="flex justify-between gap-4 w-full">
              <div className="flex gap-4 w-full">
                <SearchInput
                  containerClass={"w-full md:w-1/2"}
                  placeholder="Buscar por nombre..."
                  value={empresas_filter.nombre}
                  onChange={searchEmpresaNombre}
                />
                <MostrarPaginas
                  containerClass={"w-1/2x"}
                  defaultOption={10}
                  onChangePageSize={onChangePageSize}
                />
              </div>
              <div>
                <button
                  onClick={() => navigate(`/empresas/crear`)}
                  className="bg-sky-500 text-white rounded-md py-2 px-4"
                >
                  <div className="flex items-center justify-center">
                    <Plus size={22} />
                    <span>Añadir</span>
                  </div>
                </button>
              </div>
            </div>
            <DataTable
              containerClass={"my-8x border rounded-md mt-4"}
              data={empresas}
              loading={empresas_loading}
              config={datatableConfig}
              noHeaders={false}
              totalRecords={empresas_count}
              onPaginate={onPaginate}
              outerPageSize={PageSize}
              checkable={false}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Empresas;
