import React from "react";
import Empresas from "../pages/Empresas";
import EmpresaForm from "../pages/EmpresaForm";
import EmpresaUsuarios from "../pages/EmpresaUsuarios";

export const empresaRoutes = [
  {
    path: "/empresas",
    element: <Empresas />,
  },
  {
    path: "/empresas/crear",
    element: <EmpresaForm />,
  },
  {
    path: "/empresas/:idEmpresa/editar",
    element: <EmpresaForm />,
  },
  {
    path: "/empresas/:idEmpresa/usuarios",
    element: <EmpresaUsuarios />,
  },
];
