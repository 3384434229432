import { createSlice } from "@reduxjs/toolkit";

const usuarioSlice = createSlice({
  name: "Usuario",
  initialState: {
    usuarios: [],
    usuarios_filter: {},
    usuarios_loading: false,
    usuario: {},
    usuario_loading: false,
  },
  reducers: {
    getUsuarios: (state, { payload = {} }) => {
      state.usuarios_loading = true;
    },
    setUsuarios: (state, { payload }) => {
      state.usuarios = payload;
      state.usuarios_loading = false;
    },
    setUsuariosFilter: (state, { payload }) => {
      state.usuarios_filter = payload;
      state.usuarios_loading = true;
    },
    //
    getUsuario: (state, { payload = {} }) => {
      state.usuario_loading = true;
    },
    setUsuario: (state, { payload }) => {
      state.usuario = payload;
      state.usuario_loading = false;
    },
    //
    createUsuario: (state, { payload }) => {},
    updateUsuario: (state, { payload }) => {},
    deleteUsuario: (state, { payload }) => {},
  },
});

export const usuarioActions = usuarioSlice.actions;
export const usuarioReducer = usuarioSlice.reducer;
