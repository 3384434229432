import React from "react";
import { useSelector } from "react-redux";
import Sidebar from "src/modules/app/components/Sidebar";
import AppBar from "src/modules/common/components/AppBar";
import RootRoutes from "src/root/rootRoutes";
import { getUserFromCookie } from "src/utils/helpers";
import ScrollToTop from "../components/ScrollToTop";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import "../css/style.css";
registerLocale("es", es);

function AppPage() {
  const showSidebar = useSelector((state) => state.common.showSidebar);
  const usuario = getUserFromCookie();

  return (
    <div className="flex h-full">
      {!!showSidebar && <Sidebar></Sidebar>}
      <div className="flex flex-col w-full overflow-auto">
        <ScrollToTop>
          <AppBar />
          <div className="px-8">
            {!!usuario.es_super_admin && <RootRoutes></RootRoutes>}
            {!usuario.es_super_admin && (
              <div>No tiene permisos necesarios.</div>
            )}
          </div>
        </ScrollToTop>
      </div>
    </div>
  );
}

export default AppPage;
