import axios from "axios";
const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";

export const empresaApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  //
  return {
    list: async ({ filters }) => {
      const {
        nombre = "",
        rubroNombre = "",
        fechaVencimientoPlan = "",
        tipoPlan = "",
        ordering = "",
        pagination = {},
      } = filters;
      const { page = 1, pageSize = 10 } = pagination;
      let searchURL = `?search=${nombre}&page=${page}&page_size=${pageSize}`;
      if (rubroNombre) {
        searchURL = searchURL + `&rubro_nombre=${rubroNombre}`;
      }
      if (fechaVencimientoPlan) {
        searchURL =
          searchURL + `&fecha_vencimiento_plan=${fechaVencimientoPlan}`;
      }
      if (tipoPlan) {
        searchURL = searchURL + `&tipo_plan=${tipoPlan}`;
      }
      if (ordering) {
        searchURL = searchURL + `&ordering=${ordering}`;
      }
      //
      try {
        const empresas = await axios.get(URL_BASE + `/empresa/${searchURL}`, {
          headers,
        });
        return empresas.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("empresa list error", error);
        throw error;
      }
    },
    create: async (params) => {
      try {
        const response = await axios.post(
          URL_BASE + "/empresa/create",
          params,
          { headers }
        );
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    update: async (params) => {
      const { id } = params;
      console.log("");
      console.log("params con logotipo - empresa update", params);
      delete params.logotipo;
      console.log("params sin logotipo - empresa update", params);
      try {
        const response = await axios.put(
          URL_BASE + `/empresa/${id}/update`,
          params,
          {
            headers,
          }
        );
        console.log("url empresa update", response);
        return response;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    detail: async (id) => {
      try {
        const response = await axios.get(URL_BASE + `/empresa/${id}/detail`, {
          headers,
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    delete: async (id) => {
      try {
        const data = await axios.delete(URL_BASE + `/empresa/${id}/delete`, {
          headers,
        });
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    usuarios: async ({ id, filters }) => {
      const {
        nombre = "",
        // rubroNombre = "",
        // fechaVencimientoPlan = "",
        // tipoPlan = "",
        pagination = {},
        ordering = "",
      } = filters;
      const { page = 1, pageSize = 10 } = pagination;
      let searchURL = `?search=${nombre}&page=${page}&page_size=${pageSize}`;
      // if (tipoPlan) {
      //   searchURL = searchURL + `&tipo_plan=${tipoPlan}`;
      // }
      if (ordering) {
        searchURL = searchURL + `&ordering=${ordering}`;
      }
      //
      try {
        const response = await axios.get(
          URL_BASE + `/empresa/${id}/usuarios${searchURL}`,
          {
            headers,
          }
        );
        console.log("usuarios api response", response.data);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    loginAs: async (id) => {
      try {
        const response = await axios.post(
          URL_BASE + `/usuario/login-as`,
          { userId: id },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true, // default
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  };
};
