import { call, put, select, takeEvery } from "redux-saga/effects";
import { getToken } from "src/utils/helpers";
import { usuarioApi } from "./api";
import { usuarioActions } from "./redux";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { push } from "@lagunovsky/redux-react-router";

function* createUsuarioSaga({ payload }) {
  const token = getToken();
  const usuario = yield select((state) => state.usuario.usuario);

  const params = { ...usuario };
  try {
    const data = yield call(usuarioApi(token).create, params);
    //
    SuccessToast({ message: "Usuario creada con éxito!" });
    yield put(push("/usuarios"));
    yield put(usuarioActions.getUsuarios());
  } catch (error) {
    // yield put(usuarioActions.usuarioUpdateSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ha ocurrido un error" });
  }
}

function* updateUsuarioSaga({ payload }) {
  const token = getToken();
  const usuario = yield select((state) => state.usuario.usuario);
  const params = { ...usuario };
  //
  try {
    const data = yield call(usuarioApi(token).update, params);
    //
    SuccessToast({ message: "Usuario editada con éxito!" });
    yield put(push("/usuarios"));
    yield put(usuarioActions.getUsuarios());
  } catch (error) {
    // yield put(usuarioActions.usuarioUpdateSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ha ocurrido un error" });
  }
}

function* getUsuarioSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(usuarioApi(token).detail, payload);
    console.log("getUsuarioSaga data", data);
    yield put(usuarioActions.setUsuario(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* getUsuariosSaga({ payload }) {
  const token = getToken();
  const usuarios_filter = yield select(
    (state) => state.usuario.usuarios_filter
  );
  //
  try {
    const data = yield call(usuarioApi(token).list, {
      filters: usuarios_filter,
    });
    console.log("getUsuariosSaga data", data);
    yield put(usuarioActions.setUsuarios(data.results));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* deleteUsuarioSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(usuarioApi(token).delete, payload);
    console.log("deleteUsuarioSaga data", data);
    yield put(usuarioActions.getUsuarios());
    SuccessToast({ message: "Usuario eliminada con éxito!" });
  } catch (error) {
    ErrorToast({ message: "Ocurrió un error." });
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

export function* usuarioSagas() {
  yield takeEvery(usuarioActions.getUsuarios.type, getUsuariosSaga);
  yield takeEvery(usuarioActions.setUsuariosFilter.type, getUsuariosSaga);
  yield takeEvery(usuarioActions.getUsuario.type, getUsuarioSaga);
  yield takeEvery(usuarioActions.createUsuario.type, createUsuarioSaga);
  yield takeEvery(usuarioActions.updateUsuario.type, updateUsuarioSaga);
  yield takeEvery(usuarioActions.deleteUsuario.type, deleteUsuarioSaga);
}
