import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { commonActions } from "src/modules/common/handler/redux";
import CustomTable from "src/modules/common/components/CustomTable";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import { empresaActions } from "../handlers/redux";
import DataTable from "src/modules/common/components/DataTable";
import SearchInput from "src/modules/common/components/SearchInput";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";

function EmpresaUsuarios() {
  const dispatch = useDispatch();
  const params = useParams();
  const { idEmpresa } = params;
  const empresa = useSelector((state) => state.empresa.empresa);
  const usuarios = useSelector((state) => state.empresa.usuarios);
  const usuarios_count = useSelector((state) => state.empresa.usuarios_count);
  const usuarios_filter = useSelector((state) => state.empresa.usuarios_filter);
  const usuarios_loading = useSelector(
    (state) => state.empresa.usuarios_loading
  );
  const login_as_loading = useSelector(
    (state) => state.empresa.login_as_loading
  );
  const [PageSize, setPageSize] = React.useState(10);

  const setOrdering =
    (field) =>
    (order = "") => {
      let { ordering = "" } = usuarios_filter;
      let orderingArray = ordering.split(",") || [];
      let emptyIndex = orderingArray.indexOf("");
      if (emptyIndex) {
        orderingArray.splice(emptyIndex, 1);
      }
      let fieldIndex = orderingArray.indexOf(field);
      fieldIndex =
        fieldIndex === -1 ? orderingArray.indexOf(`-${field}`) : fieldIndex;
      //
      if (fieldIndex > 0) {
        orderingArray.splice(fieldIndex, 1);
        if (order) {
          field = order === "desc" ? `-${field}` : field;
          orderingArray[fieldIndex] = field;
        }
      } else {
        // si no está, lo agregamos
        field = order === "desc" ? `-${field}` : field;
        orderingArray.push(field);
      }
      ordering = orderingArray.join(",");
      //
      dispatch(
        empresaActions.setUsuariosFilter({
          id: empresa.id,
          filters: { ...usuarios_filter, ordering: ordering },
        })
      );
    };

  function addFilterFilter(field) {
    return (value) => {
      dispatch(
        empresaActions.setUsuariosFilter({
          id: empresa.id,
          filters: { ...usuarios_filter, [`${field}`]: value },
        })
      );
    };
  }

  const selectUsuario = (colaboradorId) => {
    dispatch(empresaActions.setLoginAsUserId(colaboradorId));
  };

  const datatableConfig = {
    headers: [
      {
        title: "Nombre",
        contentClass: "px-2 py-4",
        onOrder: setOrdering("empleado__nombre"),
        accessor: "empleado.nombre",
        contentRenderer: (item) => (
          <div>
            <div>{`${item.empleado?.nombre} ${item.empleado?.apellido}`}</div>
            <div className="text-xs text-slate-500">
              {`${
                item.login?.email
                  ? item.login?.email.includes("null.xyz")
                    ? "(Ingresa con CI)"
                    : item.login?.email
                  : "Sin email"
              }`}
            </div>
          </div>
        ),
      },
      {
        title: "Cargo",
        contentClass: "px-2 py-4",
        onOrder: setOrdering("empleado__cargo__nombre"),
        accessor: "empleado.cargo.nombre",
        contentRenderer: (item) => (
          <>{item.empleado?.cargo?.nombre || "Sin asignar"}</>
        ),
      },
      {
        title: "Rol",
        contentClass: "px-2 py-4",
        onOrder: setOrdering("login__groups__name"),
        accessor: "login.rol",
      },
    ],
    rowActions: [
      {
        label: "Iniciar sesión",
        action: (item) => {
          selectUsuario(item?.empleado?.id);
        },
      },
    ],
  };

  useEffect(() => {
    dispatch(commonActions.setTitle("Usuarios"));
    dispatch(commonActions.showSidebar(true));
    dispatch(empresaActions.getUsuarios({ id: idEmpresa }));
    if (idEmpresa) {
      dispatch(empresaActions.getEmpresa(idEmpresa));
    }
  }, []);

  useEffect(() => {
    dispatch(
      commonActions.setTitle(`Usuarios ${empresa.nombre ? empresa.nombre : ""}`)
    );
  }, [empresa]);

  const searchEmpresaNombre = (e) => {
    let value = e.target.value;
    dispatch(
      empresaActions.setUsuariosFilter({
        id: empresa.id,
        filters: { ...usuarios_filter, nombre: value },
      })
    );
  };
  const onPaginate = (page, pageSize) => {
    dispatch(
      empresaActions.setUsuariosFilter({
        id: empresa.id,
        filters: {
          ...usuarios_filter,
          pagination: {
            page: page,
            pageSize: pageSize,
          },
        },
      })
    );
  };
  const onChangePageSize = (e) => {
    let value = e.target.value;
    dispatch(
      empresaActions.setUsuariosFilter({
        id: empresa.id,
        filters: {
          ...usuarios_filter,
          pagination: {
            pageSize: parseInt(value),
            page: 1,
          },
        },
      })
    );
    setPageSize(parseInt(value));
  };
  //
  return (
    <>
      <div className="flex mb-8">
        <div className="w-full">
          <div className="container mt-4 flex flex-col">
            {!login_as_loading && (
              <>
                <div className="flex gap-4 w-full">
                  <SearchInput
                    containerClass={"w-full md:w-1/2"}
                    placeholder="Buscar por nombre..."
                    value={usuarios_filter.nombre}
                    onChange={searchEmpresaNombre}
                  />
                  <MostrarPaginas
                    containerClass={"w-1/2x"}
                    defaultOption={10}
                    onChangePageSize={onChangePageSize}
                  />
                </div>
                <DataTable
                  containerClass={"my-8x border rounded-md mt-4"}
                  data={usuarios}
                  loading={usuarios_loading}
                  config={datatableConfig}
                  noHeaders={false}
                  totalRecords={usuarios_count}
                  onPaginate={onPaginate}
                  outerPageSize={PageSize}
                  checkable={false}
                />
              </>
            )}
            {!!login_as_loading && (
              <div className="flex justify-center items-center w-full h-96">
                <LoadingSpinButton
                  message={"Cargando, por favor espere"}
                ></LoadingSpinButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default EmpresaUsuarios;
