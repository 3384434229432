import React from "react";
import UsuarioForm from "../pages/UsuarioForm";
import Usuarios from "../pages/Usuarios";

export const usuarioRoutes = [
  {
    path: "/usuarios",
    element: <Usuarios />,
  },
  {
    path: "/usuarios/crear",
    element: <UsuarioForm />,
  },
  {
    path: "/usuarios/:idUsuario/editar",
    element: <UsuarioForm />,
  },
];
