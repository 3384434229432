import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../handlers/redux";
import banner from "../../../images/banner_wel.jpg";
import regional from "../../../images/regional_logo.png";
import taxit from "../../../images/taxit_logo.png";
import cadiem from "../../../images/cadiem_logo.png";
import toyotoshi from "../../../images/toyotoshi_logo.png";
import bancard from "../../../images/bancard_logo.png";
import audi from "../../../images/audi_logo.png";

import { ReactComponent as Logo2 } from "../../../images/logo2.svg";

function LoginPage() {
  const dispatch = useDispatch();

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const login_loading = useSelector((state) => state.auth.login_loading);
  const error_message = useSelector((state) => state.auth.error_message);
  const empresas = [regional, taxit, cadiem, toyotoshi, bancard, audi];

  const onLoginPress = (event) => {
    event.preventDefault();
    if (!user || !password) return;

    dispatch(authActions.login({ user, password }));
  };

  const renderEmpresas = () => {
    return (
      <div className="flex flex-row justify-center">
        <div className="flex flex-col p-4 gap-4">
          <div className="text-2xl font-bold">
            Empresas que se sumaron al cambio
          </div>
          <div className="flex flex-row gap-2">
            {empresas.map((logo, idx) => {
              return (
                <img
                  key={`logo-${idx}`}
                  className="border rounded-lg object-center object-contain w-40 h-40 p-4"
                  src={logo}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className="flex flex-col items-center p-6 shadow-lg rounded-lg shadow/lg bg-white gap-4">
        <div className="text-4xl text-sky-400 p-8 px-20">Iniciar sesión</div>
        {!login_loading && (
          <form
            onSubmit={onLoginPress}
            className={"w-full flex flex-col gap-8"}
          >
            <div className="flex flex-col gap-6 text-gray-600">
              <div className="input flex flex-col gap-2">
                <span className="text-sm font-bold">Usuario</span>
                <input
                  className="py-2 px-2 rounded border focus:border-sky-300 focus:outline-none"
                  type="text"
                  placeholder=""
                  value={user}
                  onChange={({ target }) => setUser(target.value)}
                />
              </div>
              <div className="input flex flex-col gap-2">
                <span className="text-sm font-bold">Contraseña</span>
                <input
                  className="py-2 px-2 rounded border focus:outline-none"
                  type="password"
                  placeholder=""
                  value={password}
                  onChange={({ target }) => setPassword(target.value)}
                />
              </div>
            </div>
            {/*  */}
            {!!error_message && (
              <div className="flex justify-center bg-red-300 p-2 rounded-md font-semibold">
                <div className="text-red-700 text-sm">{error_message}</div>
              </div>
            )}
            {/*  */}
            <div className="input flex flex-col ">
              <button
                type="submit"
                className="bg-sky-500 text-white font-semibold leading-6 text-md py-2 px-4 rounded hover:rounded-lg transition-all ease-linear hover:duration-75 duration-400 hover:bg-green-400"
              >
                Ingresar
              </button>
            </div>
          </form>
        )}
        {!!login_loading && (
          <div className="w-full flex flex-col gap-8">
            <div className="flex flex-col gap-6 text-gray-600 animate-pulse">
              <div className="input flex flex-col gap-2">
                <span className="text-sm font-bold">Usuario</span>
                <div className="py-2 px-2 rounded border border-zinc-100 focus:border-sky-300 focus:outline-none h-10 bg-zinc-100"></div>
              </div>
              <div className="input flex flex-col gap-2">
                <span className="text-sm font-bold">Contraseña</span>
                <div className="py-2 px-2 rounded border border-zinc-100 focus:outline-none h-10 bg-zinc-100"></div>
              </div>
            </div>
            <div className="inline-flex items-center justify-center px-4 py-2 font-semibold leading-6 text-md shadow rounded-md text-white bg-sky-500 hover:bg-sky-400 transition ease-in-out duration-150 cursor-not-allowed">
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Ingresando...
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="border-b  bg-white flex flex-row items-center justify-between px-4">
        <div className="flex h-12 flex-row gap-4 items-center">
          <Logo2 className="w-8"></Logo2>
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="border-t  bg-white flex flex-row items-center justify-between px-4">
        <div className="flex h-12 flex-row gap-4 items-center">
          <Logo2 className="w-8"></Logo2>
        </div>
        <div className="text-sm flex flex-col items-center justify-between">
          <div className="">
            Loffice Bulnes- Bulnes 830 c/ Avda.España, Piso 1
          </div>
          <div className="flex justify-center text-sm">
            Copyright YOICA S.A. 2017. Todos los derechos reservados.
          </div>
        </div>
        <div className="">
          <a href="mailto:contacto@yoica.com.py" target="_top">
            <span className="font-bold text-md text-zinc-800">
              Contactar a ventas
            </span>
          </a>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="flex flex-col gap-4 flex-grow">
        <div
          className="flex flex-col gap-4 p-8 bg-cover"
          style={{ backgroundImage: `url(${banner})` }}
        >
          <div className="flex flex-row justify-center">{renderForm()}</div>
        </div>
        {renderEmpresas()}
      </div>
    );
  };

  return (
    <div className="relative flex flex-col h-full ">
      {renderHeader()}
      {renderBody()}
      {renderFooter()}
    </div>
  );
}

export default LoginPage;
