import { createRouterMiddleware } from "@lagunovsky/redux-react-router";
import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { rootReducer } from "src/root/rootReducer";
import { rootSagas } from "src/root/rootSagas";
//
// import { persistStore, persistReducer } from "redux-persist";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const browserHistory = createBrowserHistory();
const persistConfig = {
  key: "root",
  storage,
};

// middleware
const routerMiddleware = createRouterMiddleware(browserHistory);
let sagaMiddleware = createSagaMiddleware();

const middleware = [
  ...getDefaultMiddleware({
    thunk: false,
    serializableCheck: false,
    // serializableCheck: {
    //   ignoredActions: ["navigate/setNavigate"],
    //   ignoredPaths: ["navigate"],
    // },
  }),
  routerMiddleware,
  sagaMiddleware,
];

//
const rootReducerWithHistory = rootReducer(browserHistory);

const persistedReducer = persistReducer(persistConfig, rootReducerWithHistory);

const store = configureStore({
  reducer: persistedReducer,
  middleware,
});

sagaMiddleware.run(rootSagas);

export const rootStore = store;
