import React, { useState } from "react";
import { CaretDownFill, PersonCircle } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import backButton from "src/modules/common/components/images/back-arrow.png";
import { getUserFromCookie } from "src/utils/helpers";
import { commonActions } from "../handler/redux";
import "./css/AppBar.css";

function AppBar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const NORMAL_APP_URL =
    process.env.REACT_APP_NORMAL_APP_URL || "http://localhost:3001/";
  const [menuClick, setMenuClick] = useState(false);

  const usuario = getUserFromCookie();
  const title = useSelector((state) => state.common.title);
  const titleMenu = useSelector((state) => state.common.titleMenu);
  const sidebarCollapse = useSelector((state) => state.common.sidebarCollapse);

  const showSidebar = useSelector((state) => state.common.showSidebar);
  const hasSecondBar = useSelector((state) => state.common.hasSecondBar);
  const secondBarTitle = useSelector((state) => state.common.secondBarTitle);
  const secondBarBackRoute = useSelector(
    (state) => state.common.secondBarBackRoute
  );
  const goBack = () => {
    navigate(secondBarBackRoute);
    dispatch(
      commonActions.setSecondBar({
        hasSecondBar: false,
        secondBarTitle: "",
        secondBarBackRoute: "",
      })
    );
  };

  const toggleMenu = () => {
    setMenuClick(!menuClick);
  };

  return (
    <>
      <div className="flex flex-col gap-0 w-100 bg-white border-b">
        <div className="flex justify-between w-full">
          <div className="flex flex-row gap-3 items-center px-4">
            <h6 className="font-bold text-2xl">{title}</h6>
            <div className="flex flex-row gap-2 items-end">
              {titleMenu.map((item) => (
                <NavLink
                  key={item.to}
                  to={item.to}
                  end
                  className={({ isActive }) =>
                    isActive
                      ? "text-sm rounded px-2 py-1 bg-zinc-100 border border-sky-500 active"
                      : "text-sm rounded px-2 py-1 bg-zinc-100 border border-zinc-100"
                  }
                >
                  {item.name}
                </NavLink>
              ))}
            </div>
          </div>
          <div className="cuenta-container flex items-center gap-2 mr-7">
            {usuario?.foto_empleado === "None" && (
              <PersonCircle color="#0ba3fb" size={34} />
            )}
            {usuario?.foto_empleado !== "None" && (
              <div className="w-12 bg-slate-200 rounded-md">
                <img
                  className="object-cover h-12 w-12 rounded-md"
                  src={usuario.foto_empleado}
                  alt={`Foto usuario`}
                />
              </div>
            )}
            <span className="ml-1 text-sky-500">
              {usuario.nombre || "Sin nombre"}
            </span>
            <div className="borderx relative z-10">
              <div className="hover:cursor-pointer" onClick={toggleMenu}>
                <CaretDownFill></CaretDownFill>
              </div>
              {/* <ul
                className={`${
                  menuClick ? "" : "hidden"
                } bg-white flex flex-col gap-1 absolute right-0 border w-44 p-0`}
              >
                <li className="hover:bg-sky-400 p-2">
                  <a
                    target={`_blank`}
                    href={NORMAL_APP_URL}
                    onClick={(e) => setMenuClick(false)}
                  >
                    Ir a vista Colaborador
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
        {/*  */}
        {hasSecondBar && (
          <div className="flex items-center bg-gray-100 py-2 px-6 w-full">
            <div
              onClick={goBack}
              className={
                "h-full  flex items-center pr-2 -mr-2 z-10 cursor-pointer"
              }
            >
              <img className="back" src={backButton} alt="Regresar" />
            </div>
            <div className="font-medium px-4 text-[20px]">{secondBarTitle}</div>
          </div>
        )}
      </div>
    </>
  );
}

export default AppBar;
