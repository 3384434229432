import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { rubroActions } from "src/modules/rubro/handlers/redux";
import { TIPO_PLANES } from "src/modules/common/helpers/constants";
import CustomDatePicker from "src/modules/common/components/CustomDatePicker";
import { empresaActions } from "../handlers/redux";
import { useParams } from "react-router-dom";
import { formatDate } from "src/modules/common/helpers/helpers";
import { format, parse, parseISO } from "date-fns";
import { formatInTimeZone, utcToZonedTime } from "date-fns-tz";

const UsuarioField = ({ field, formik }) => {
  const { label, name, type = "text", required = false } = field;
  // console.log("ufield", field);
  return (
    <div className="flex flex-col gap-2">
      <label className="font-semibold" htmlFor="name">
        {label}
        {!!required && <span className="text-red-500">*</span>}
      </label>
      <input
        id={name}
        name={name}
        type={type}
        className="border rounded-md p-1"
        onChange={formik.handleChange}
        value={formik.values[name]}
      />
      {formik.touched[name] && formik.errors[name] && (
        <div className="text-red-500">{formik.errors[name]}</div>
      )}
    </div>
  );
};

const EmpresaForm = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { idEmpresa } = params;
  const rubros = useSelector((state) => state.rubro.rubros);
  const empresa = useSelector((state) => state.empresa.empresa);
  const initialValues = {
    nombre: "",
    rubro: "",
    logotipo: null,
    tipo_plan: "",
    fecha_vencimiento_plan: "",
    //
    first_name: "",
    last_name: "",
    ci: "",
    email: "",
    password: "",
    confirmPassword: "",
  };
  const validationSchema = Yup.object({
    nombre: Yup.string().required("Campo requerido"),
    rubro: Yup.string().required("Campo requerido"),
    tipo_plan: Yup.string().required("Campo requerido"),
    fecha_vencimiento_plan: Yup.string().required("Campo requerido"),
    //
    first_name: Yup.string().required("Campo requerido"),
    last_name: Yup.string().required("Campo requerido"),
    ci: Yup.string().required("Campo requerido"),
    email: Yup.string()
      .email("Ingresa un correo electrónico válido")
      .required("Campo requerido"),
    password: Yup.string()
      // .min(8, "La contraseña debe tener al menos 8 caracteres")
      .required("La contraseña es obligatoria"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Las contraseñas no coinciden")
      .required("Confirma tu contraseña"),
  });
  const validationSchemaEdit = Yup.object({
    nombre: Yup.string().required("Campo requerido"),
    rubro: Yup.string().required("Campo requerido"),
    tipo_plan: Yup.string().required("Campo requerido"),
    fecha_vencimiento_plan: Yup.string().required("Campo requerido"),
  });

  const onSubmit = (values) => {
    console.log("onSubmit", values);
    let data = { ...values };
    console.log(
      "values.fecha_vencimiento_plan",
      typeof values.fecha_vencimiento_plan
    );
    const fechaVencimiento = values.fecha_vencimiento_plan;
    //string
    if (fechaVencimiento) {
      if (typeof fechaVencimiento === "string") {
        //
      } else {
        data.fecha_vencimiento_plan = format(
          values.fecha_vencimiento_plan,
          "yyyy-MM-dd"
        );
      }
    }
    //
    dispatch(empresaActions.setEmpresa(data));
    if (idEmpresa) {
      dispatch(empresaActions.updateEmpresa());
    } else {
      dispatch(empresaActions.createEmpresa());
    }
    //
  };
  const formik = useFormik({
    initialValues,
    validationSchema: !!idEmpresa ? validationSchemaEdit : validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  //
  useEffect(() => {
    dispatch(rubroActions.getRubros());
    dispatch(empresaActions.setEmpresa({}));
    if (!!idEmpresa) {
      dispatch(empresaActions.getEmpresa(idEmpresa));
    }
  }, []);

  useEffect(() => {
    formik.setValues({
      ...empresa,
    });
  }, [empresa]);

  const adminFields = [
    { label: "Nombre", name: "first_name", type: "text", required: true },
    { label: "Apellido", name: "last_name", type: "text", required: true },
    { label: "CI", name: "ci", type: "text", required: true },
    { label: "Email", name: "email", type: "email", required: true },
    { label: "Contraseña", name: "password", type: "password", required: true },
    {
      label: "Confirmar contraseña",
      name: "confirmPassword",
      type: "password",
      required: true,
    },
  ];

  return (
    <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2 mt-4">
      <div className="flex flex-col gap-2">
        <label className="font-semibold" htmlFor="name">
          Nombre<span className="text-red-500">*</span>
        </label>
        <input
          id="nombre"
          name="nombre"
          type="text"
          className="border rounded-md p-1"
          onChange={formik.handleChange}
          value={formik.values.nombre}
        />
        {formik.touched.nombre && formik.errors.nombre && (
          <div className="text-red-500">{formik.errors.nombre}</div>
        )}
      </div>
      <div className="flex flex-col gap-2">
        <label className="font-semibold" htmlFor="rubro">
          Rubro<span className="text-red-500">*</span>
        </label>
        <select
          id="rubro"
          name="rubro"
          className="border rounded-md p-1"
          onChange={formik.handleChange}
          value={formik.values.rubro}
        >
          <option value="">Seleccione una opción</option>
          {rubros.map((rubro, ridx) => {
            return (
              <option key={`ro-${ridx}`} value={`${rubro.id}`}>
                {rubro.nombre}
              </option>
            );
          })}
        </select>
        {formik.touched.rubro && formik.errors.rubro && (
          <div className="text-red-500">{formik.errors.rubro}</div>
        )}
      </div>
      <div className="flex flex-col gap-2">
        <label className="font-semibold" htmlFor="tipo_plan">
          Plan<span className="text-red-500">*</span>
        </label>
        <select
          id="tipo_plan"
          name="tipo_plan"
          className="border rounded-md p-1"
          onChange={formik.handleChange}
          value={formik.values.tipo_plan}
        >
          <option value="">Seleccione una opción</option>
          {TIPO_PLANES.map((tipo, tidx) => (
            <option key={`tpo-${tidx}`} value={`${tipo.value}`}>
              {tipo.label}
            </option>
          ))}
        </select>
        {formik.touched.tipo_plan && formik.errors.tipo_plan && (
          <div className="text-red-500">{formik.errors.tipo_plan}</div>
        )}
      </div>
      <div className="flex flex-col gap-2">
        <label className="font-semibold" htmlFor="tipo_plan">
          Fecha de vencimiento<span className="text-red-500">*</span>
        </label> 
        <DatePicker
          className="border rounded placeholder-zinc-600 border-zinc-200 p-1 w-full"
          locale="es"
          selected={Date.parse(formik.values.fecha_vencimiento_plan)}
          onChange={(date) => {
            formik.setFieldValue("fecha_vencimiento_plan", date);
          }}
          dateFormat="dd/MM/yyyy"
        />
        {formik.touched.fecha_vencimiento_plan &&
          formik.errors.fecha_vencimiento_plan && (
            <div className="text-red-500">
              {formik.errors.fecha_vencimiento_plan}
            </div>
          )}
      </div>

      {/* <div className="flex flex-col gap-2">
        <label className="font-semibold" htmlFor="tipo_plan">
          Logo
        </label>
        <input
          id="logotipo"
          name="logotipo"
          type="file"
          className="border rounded-md p-1"
          onChange={formik.handleChange}
          value={formik.values.logotipo}
        />
      </div> */}

      {/* COLABORADOR ADMIN */}
      {!idEmpresa && (
        <>
          <h1 className="font-bold mt-8 mb-4 text-lg">Colaborador Admin</h1>
          {adminFields.map((field, fidx) => (
            <UsuarioField key={`uf-${fidx}`} field={field} formik={formik} />
          ))}
        </>
      )}
      <button
        type="submit"
        className="rounded-md bg-sky-500 px-1 py-2 text-white mt-2"
      >
        Guardar
      </button>
    </form>
  );
};

export default EmpresaForm;
