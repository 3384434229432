import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AccionMenu } from "src/modules/common/components/AccionMenu";
import { AccionMenuItem } from "src/modules/common/components/AccionMenuItem";
import CustomTable from "src/modules/common/components/CustomTable";
import { commonActions } from "src/modules/common/handler/redux";
import { TIPO_PLANES } from "src/modules/common/helpers/constants";
import { formatDate } from "src/modules/common/helpers/helpers";
import { usuarioActions } from "../handlers/redux";

const UsuarioRowComponent = ({ item }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const paddingCell = "p-4";
  const onEditar = () => {
    navigate(`${item.id}/editar`);
  };

  const eliminarUsuarioHandler = (usuarioId) => {
    let deseaEliminar = window.confirm(
      "Está seguro que desea eliminar la usuario seleccionada?"
    );
    if (deseaEliminar) {
      dispatch(usuarioActions.deleteUsuario(usuarioId));
    }
  };
  //
  return (
    <div className="table-row border-b border-gray-200">
      <div
        className={`table-cell ${paddingCell}`}
      >{`${item.first_name} ${item.last_name}`}</div>
      <div className={`table-cell ${paddingCell}`}>{item.email}</div>
      <div className={`table-cell ${paddingCell}`}>
        {item.is_active ? "Activo" : "Desactivado"}
      </div>
      {/* <div className={`table-cell ${paddingCell}`}>
        {formatDate(item.fecha_vencimiento_plan)}
      </div> */}
      <div className={`table-cell ${paddingCell}`}>
        <div className="p-1 w-24 flex flex-row items-center">
          <AccionMenu>
            <AccionMenuItem onClick={onEditar}>Editar</AccionMenuItem>
            <AccionMenuItem onClick={() => eliminarUsuarioHandler(item.id)}>
              <span className="text-red-500">Eliminar</span>
            </AccionMenuItem>
          </AccionMenu>
        </div>
      </div>
    </div>
  );
};

function Usuarios() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const usuarios = useSelector((state) => state.usuario.usuarios);
  const usuarios_loading = useSelector(
    (state) => state.usuario.usuarios_loading
  );
  const usuarios_filter = useSelector((state) => state.usuario.usuarios_filter);

  React.useEffect(() => {
    dispatch(commonActions.setTitle("Usuarios"));
    dispatch(commonActions.showSidebar(true));
    dispatch(usuarioActions.getUsuarios());
    !!process.env.REACT_APP_DEBUG && console.info("usuarios", usuarios);
  }, []);
  //
  const setOrdering =
    (field) =>
    (order = "") => {
      let { ordering = "" } = usuarios_filter;
      let orderingArray = ordering.split(",") || [];
      let emptyIndex = orderingArray.indexOf("");
      if (emptyIndex) {
        orderingArray.splice(emptyIndex, 1);
      }
      let fieldIndex = orderingArray.indexOf(field);
      fieldIndex =
        fieldIndex === -1 ? orderingArray.indexOf(`-${field}`) : fieldIndex;
      // si ya esta el campo, vemos si se modifica o elimina
      if (fieldIndex > 0) {
        orderingArray.splice(fieldIndex, 1);
        if (order) {
          field = order === "desc" ? `-${field}` : field;
          orderingArray[fieldIndex] = field;
        }
      } else {
        // si no está, lo agregamos
        field = order === "desc" ? `-${field}` : field;
        orderingArray.push(field);
      }
      ordering = orderingArray.join(",");
      //
      dispatch(
        usuarioActions.setUsuariosFilter({
          ...usuarios_filter,
          ordering: ordering,
        })
      );
    };

  function addFilterFilter(field) {
    return (value) => {
      dispatch(
        usuarioActions.setUsuariosFilter({
          ...usuarios_filter,
          [`${field}`]: value,
        })
      );
    };
  }
  const headers = [
    {
      label: "Nombre",
      onOrder: setOrdering("name"),
      onFilter: addFilterFilter("name"),
    },
    {
      label: "Email",
      onOrder: setOrdering("email"),
      onFilter: addFilterFilter("email"),
    },
    {
      label: "Estado",
      onOrder: setOrdering("is_active"),
      onFilter: false,
    },
    { label: "", onOrder: false, onFilter: false },
  ];

  const searchUsuarioNombre = (e) => {
    let value = e.target.value;
    dispatch(
      usuarioActions.setUsuariosFilter({
        ...usuarios_filter,
        name: value,
      })
    );
  };
  //
  return (
    <>
      <div className="flex">
        <div className="w-full">
          <div className="container mt-4 flex flex-col">
            <CustomTable
              headers={headers}
              data={usuarios}
              RowComponent={UsuarioRowComponent}
              onActionButton={() => navigate(`/usuarios/crear`)}
              onSearch={searchUsuarioNombre}
              searchValue={usuarios_filter.username}
              loading={usuarios_loading}
            ></CustomTable>
          </div>
        </div>
      </div>
    </>
  );
}

export default Usuarios;
